// 删除已入库的手表设备
const delDeviceByIdURL = `/gateway/hc-health/manage/watchDevice/delDeviceById`;
// 通过设备ID解除绑定接口
const unbindURL = `/gateway/hc-health/manage/watchDevice/unbind`;
// 管理后台查询手表设备分页列表 /manage/watchDevice/fetchDeviceList
const fetchDeviceListURL = `/gateway/hc-health/manage/watchDevice/fetchDeviceList`;

// 导入手表设备
const importWatchDeviceURL = `/gateway/hc-health/manage/watchDevice/importWatchDevice`;

export {
  delDeviceByIdURL,
  unbindURL,
  fetchDeviceListURL,
  importWatchDeviceURL,
};
