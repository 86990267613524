import { mapHelper } from "@/utils/common.js";

// 设备状态
const deviceStatus = [
  {
    value: 0,
    label: "关机",
  },
  {
    value: 1,
    label: "开机",
  },
  {
    value: 2,
    label: "开机",
  },
];

const { map: deviceStatusMap, setOps: deviceStatusOps } =
  mapHelper.setMap(deviceStatus);

const switchStatusOps = [
  {
    value: 0,
    label: "关机",
  },
  {
    value: 1,
    label: "开机",
  },
];

export { deviceStatus, deviceStatusMap, deviceStatusOps, switchStatusOps };
