<template>
  <div class="page">
    <v-list
      ref="list"
      :isAdd="true"
      :headers="headers"
      :tableUrl="tableUrl"
      :searchParam="searchParam"
      :extraParams="extraParams"
    >
      <template #headBtnSlot>
        <v-button text="导入" @click="toImport"></v-button>
      </template>
      <template #searchSlot>
        <v-input clearable label="IMEI编号" v-model="searchParam.imei" />
        <v-input
          clearable
          label="用户联系电话"
          v-model="searchParam.bindUserPhone"
        />
        <v-select
          clearable
          v-model="searchParam.switchStatus"
          :options="switchStatusOps"
          label="设备状态"
        />
      </template>
      <template #operateSlot="scope">
        <v-button
          text="解绑"
          v-if="scope.row.bindUserId"
          type="text"
          @click="unbind(scope.row)"
        />
        <v-button text="删除" type="text" @click="remove(scope.row)" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { delDeviceByIdURL, fetchDeviceListURL, unbindURL } from "./api.js";
import { deviceStatusMap, switchStatusOps } from "./map.js";

export default {
  name: "elderCareBracelet",
  data() {
    return {
      tableUrl: fetchDeviceListURL,
      searchParam: {
        imei: "",
        bindUserPhone: "",
        switchStatus: undefined,
      },
      switchStatusOps,
      headers: [
        {
          prop: "imei",
          label: "IMEI编号",
        },
        {
          prop: "bindUserNickName",
          label: "用户昵称",
        },
        {
          prop: "bindUserPhone",
          label: "用户联系电话",
        },
        {
          prop: "switchStatus",
          label: "设备状态",
          formatter: (row, prop) => {
            return deviceStatusMap[row[prop]];
          },
        },
      ],
    };
  },
  computed: {
    ...mapState("app", ["userInfo"]),
    extraParams() {
      return { tenantId: this.userInfo.tenantId };
    },
  },
  methods: {
    // 导入
    toImport() {
      this.$router.push({
        name: "elderCareBraceletImport",
      });
    },
    // 删除
    remove(item) {
      if (item.bindUserId) {
        this.$message("请先对该设备进行解绑！");
        return;
      }
      this.$confirm("是否删除该设备", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          let params = {
            deviceId: item.id,
          };
          this.$axios
            .post(`${delDeviceByIdURL}`, this.$qs.stringify(params))
            .then((res) => {
              if (res.code === 200) {
                this.$message.success("操作成功！");
                this.$refs.list.search();
              }
            });
        })
        .catch(() => {});
    },
    //
    unbind(row) {
      this.$confirm("解除绑定后设备会重置，是否确认解除绑定！", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$axios
          .post(`${unbindURL}`, {
            userId: row.bindUserId,
            deviceId: row.id,
          })
          .then((res) => {
            if (res.code == 200 && res.success) {
              this.$message.success("该设备已成功解绑！");
              this.$refs.list.search();
              return;
            }
          });
      });
    },
  },
};
</script>

<style scoped lang="less">
.page {
  box-sizing: border-box;
  height: 100%;
}
</style>
